// Style
import "./App.css";
// Router
import { BrowserRouter, Routes, Route } from "react-router-dom";
// Components
// import Navbar from "./components/Navbar";
// Pages
import Home from "./pages/Home";
import Load from "./pages/Load";
import About from "./pages/About";

function App() {
	return (
		<BrowserRouter>
			<div className="App">
				<Routes>
					<Route exact path="/" element={<Load />}></Route>
					<Route path="/home" element={<Home />}></Route>
					<Route path="/about" element={<About />}></Route>
				</Routes>
			</div>
		</BrowserRouter>
	);
}

export default App;
