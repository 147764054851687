import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";

function Home() {
	return (
		<AwesomeSlider bullets={false} animation="cubeAnimation">
			<div data-src="/images/bg0.jpg"></div>
			<div data-src="/images/bg1.png"></div>
		</AwesomeSlider>
	);
}

export default Home;
