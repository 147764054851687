import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

function Load() {
	/** Set loading to false by default */
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		/**  Set 3s for loading for now */
		setTimeout(() => {
			setLoading(false);
		}, 4000);
	}, []);

	return loading ? (
		<ClimbingBoxLoader color={"#36D7B7"} loading={loading} size={20} />
	) : (
		<Navigate to="/home"></Navigate>
	);
}

export default Load;
